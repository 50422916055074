import ChartControlV2, { ExtendedDateView } from '@components/ChartControlV2/ChartControlV2'
import DateRangeModal from '@components/DateRangeModal/DateRangeModal'
import DtpCarousel from '@components/DtpCarousel/DtpCarousel'
import DtpCompareCardV2 from '@components/DtpCompareCardV2/DtpCompareCardV2'
import DtpConsumptionSwitch from '@components/DtpConsumptionSwitch/DtpConsumptionSwitch'
import { DtpDropdown } from '@components/DtpDropdown/DtpDropdown'
import DtpTabs from '@components/DtpTabs/DtpTabs'
import ConsumptionSwitchSkeleton from '@components/Skeletons/ConsumptionSwitchSkeleton'
import { AggregationEnum } from '@enums/aggregationTypes'
import { getAggregationType, isValidModalDates, getDefaultMinMaxDateFromContract } from '@helpers/dateFunctions'
import { useLastAvailableDayV2 } from '@hooks/useLastAvailableDayV2/useLastAvailableDayV2'
import useLocationsData, { IAddOn } from '@hooks/useLocations/useLocationsData'
import { useMeasurementsV2 } from '@hooks/useMeasurementsV2/useMeasurementsV2'
import { usePrevious } from '@hooks/usePrevious/usePrevious'
import { MeasurmentsType } from '@interfaces/measurements'
import { CircularProgress, Container, Typography, Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { getAggregationsTimePeriod, switchOptions } from './utils'

const EnergyConsumption = () => {
  const {
    allLocations,
    activeLocations,
    expiredLocations,
    selectedItem,
    setSelectedItem,
    isLoading: isLoadingContracts,
    showExpiredContracts,
    handleChangeExpireContracts,
  } = useLocationsData()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [activeTab, setActiveTab] = useState(1)
  const [modalOpen, setModalOpen] = useState(false)
  const [date, setDate] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isCustomTab, setIsCustomTab] = useState(false)
  const [selectedFromConsumptionDetail, setSelectedFromConsumptionDetail] = useState('')
  const [customAggregationType, setCustomAggregationType] = useState<AggregationEnum>(AggregationEnum.DAY)
  const [customChartType, setCustomChartType] = useState<ExtendedDateView>('day')
  const [selectedFromComparison, setSelectedFromComparison] = useState('')
  const [graphType, setGraphType] = useState<string | null>(switchOptions[0].value)

  const { addOn } = selectedItem ?? {}

  const { meterId, locationId, supplyEndDate, supplyStartDate, contractId } = addOn ?? ({ gpnr: '' } as IAddOn)

  const previousMeterId = usePrevious(meterId)

  const aggregationType = {
    0: AggregationEnum.HOUR,
    1: AggregationEnum.DAY,
    2: AggregationEnum.MONTH,
    3: AggregationEnum.YEAR,
  } as const

  const chartTypeAgg = {
    0: 'day',
    1: 'month',
    2: 'year',
  } as const

  const aggregation = aggregationType[activeTab as keyof typeof aggregationType]
  const chartTypeAggregation = chartTypeAgg[activeTab as keyof typeof chartTypeAgg]

  const { lastAvailableDay, isLoadingLastAvailableDay } = useLastAvailableDayV2()

  const { data: aggregationData, isLoading: isLoadingMeasurements } = useMeasurementsV2({
    ...getAggregationsTimePeriod(date, aggregation, isCustomTab, startDate, endDate),
    skip: isLoadingLastAvailableDay,
  })
  const { minDate, maxDate } = useMemo(
    () =>
      getDefaultMinMaxDateFromContract(
        chartTypeAggregation,
        lastAvailableDay ?? '',
        supplyEndDate ?? '',
        supplyStartDate ?? '',
        'YYYY-MM-DD'
      ),
    [chartTypeAggregation, lastAvailableDay, supplyStartDate, supplyEndDate]
  )

  const datePickerValue = selectedFromConsumptionDetail?.length ? selectedFromConsumptionDetail : maxDate

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    if (newValue === 3) {
      setModalOpen(!startDate.length)
      setIsCustomTab(true)
      setGraphType(switchOptions[0].value)
    } else {
      setActiveTab(newValue)
      setIsCustomTab(false)
    }
  }

  const handleConfirmModal = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const aggregation = getAggregationType(startDate, endDate)
    setCustomAggregationType(aggregation)
    if (aggregation === AggregationEnum.HOUR) {
      setCustomChartType('day')
    } else if (aggregation === AggregationEnum.DAY) {
      setCustomChartType('month')
    } else if (aggregation === AggregationEnum.MONTH) {
      setCustomChartType('year')
    } else {
      setCustomChartType('years')
    }
    setStartDate(startDate)
    setEndDate(endDate)
    setIsCustomTab(true)
    setActiveTab(3)
    setModalOpen(false)
  }

  const handleCustomRangeClick = () => {
    if (isValidModalDates(startDate, endDate, supplyStartDate)) {
      setActiveTab(3)
      setIsCustomTab(true)
    } else {
      setModalOpen(true)
      setIsCustomTab(false)
    }
  }

  const handleOnLabelClick = () => {
    const maxPowerDate = aggregationData?.maxPowerPerMonth?.[0]?.timestamp
    if (maxPowerDate) {
      activeTab === 0 ? setSelectedFromComparison(maxPowerDate) : setSelectedFromConsumptionDetail(maxPowerDate)
      setActiveTab(0)
    }
  }

  const handleComparisonBarClick = useCallback((value: string, tab: number) => {
    setSelectedFromConsumptionDetail(value)
    setActiveTab(tab)
    setGraphType(switchOptions[0].value)
  }, [])

  useEffect(() => {
    if (state) {
      const { value, tab, billingPeriodFromDate, billingPeriodToDate } = state
      setActiveTab(tab)
      setSelectedFromConsumptionDetail(value)

      if (!!billingPeriodFromDate && !!billingPeriodToDate) {
        handleConfirmModal({
          startDate: billingPeriodFromDate,
          endDate: billingPeriodToDate,
        })
      }
    }
  }, [state, setSelectedItem, allLocations])

  useEffect(() => {
    if (selectedFromConsumptionDetail) {
      setSelectedFromComparison(selectedFromConsumptionDetail)
      setSelectedFromConsumptionDetail('')
      setIsCustomTab(false)
    }
  }, [selectedFromConsumptionDetail])

  const isComparisonCardVisible = activeTab !== 3
  const type = aggregationData?.type as MeasurmentsType
  const hasChfValues = !!aggregationData?.chfValueAvailable
  const hasReactivePower = !!aggregationData?.reactivePowerAvailable
  const isMaxPowerAvailable = !!aggregationData?.maxPowerAvailable

  const showConsumptionSwitch =
    type === 'consumption' &&
    (isMaxPowerAvailable || hasReactivePower) &&
    !isLoadingMeasurements &&
    !isLoadingLastAvailableDay

  useEffect(() => {
    if (aggregationData?.maxPowerAvailable === false || previousMeterId !== meterId) {
      setGraphType(switchOptions[0].value)
    }
  }, [meterId, locationId, contractId, aggregationData, previousMeterId])

  const tabs = [
    {
      label: t('day'),
      component: (
        <ChartControlV2
          addOn={selectedItem?.addOn ?? ({ gpnr: '' } as IAddOn)}
          minimumDatePickerDate={minDate}
          maximumDatePickerDate={maxDate}
          datePickerValue={datePickerValue}
          chartType="day"
          setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
          selectedFromComparison={selectedFromComparison}
          setSelectedFromComparison={setSelectedFromComparison}
          onLabelClick={handleOnLabelClick}
          isLoading={isLoadingLastAvailableDay}
          isLoadingAggregation={isLoadingMeasurements}
          startModalDate={startDate}
          endModalDate={endDate}
          aggregationType={AggregationEnum.HOUR}
          handleModalOpen={() => setModalOpen(true)}
          aggregationData={aggregationData}
          date={date}
          setDate={setDate}
          type={type}
          hasChfValues={hasChfValues}
          graphType={graphType}
        />
      ),
    },
    {
      label: t('month'),
      component: (
        <ChartControlV2
          addOn={selectedItem?.addOn ?? ({ gpnr: '' } as IAddOn)}
          minimumDatePickerDate={minDate}
          maximumDatePickerDate={maxDate}
          datePickerValue={datePickerValue}
          chartType="month"
          setActiveTab={setActiveTab}
          setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
          startModalDate={startDate}
          endModalDate={endDate}
          isLoading={isLoadingLastAvailableDay}
          isLoadingAggregation={isLoadingMeasurements}
          aggregationType={AggregationEnum.DAY}
          handleModalOpen={() => setModalOpen(true)}
          selectedFromComparison={selectedFromComparison}
          setSelectedFromComparison={setSelectedFromComparison}
          onLabelClick={handleOnLabelClick}
          aggregationData={aggregationData}
          date={date}
          setDate={setDate}
          type={type}
          hasChfValues={hasChfValues}
          graphType={graphType}
        />
      ),
    },
    {
      label: t('year'),
      component: (
        <ChartControlV2
          addOn={selectedItem?.addOn ?? ({ gpnr: '' } as IAddOn)}
          minimumDatePickerDate={minDate}
          maximumDatePickerDate={maxDate}
          datePickerValue={datePickerValue}
          chartType="year"
          setActiveTab={setActiveTab}
          setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
          startModalDate={startDate}
          endModalDate={endDate}
          isLoading={isLoadingLastAvailableDay}
          isLoadingAggregation={isLoadingMeasurements}
          aggregationType={AggregationEnum.MONTH}
          handleModalOpen={() => setModalOpen(true)}
          selectedFromComparison={selectedFromComparison}
          setSelectedFromComparison={setSelectedFromComparison}
          onLabelClick={handleOnLabelClick}
          aggregationData={aggregationData}
          date={date}
          setDate={setDate}
          type={type}
          hasChfValues={hasChfValues}
          graphType={graphType}
        />
      ),
    },
    {
      label: t('power_consumption_page.energy_consumption_custom_date'),
      component: (
        <ChartControlV2
          addOn={selectedItem?.addOn ?? ({ gpnr: '' } as IAddOn)}
          startModalDate={startDate}
          endModalDate={endDate}
          chartType={customChartType}
          setActiveTab={setActiveTab}
          setSelectedFromConsumptionDetail={setSelectedFromConsumptionDetail}
          onLabelClick={handleOnLabelClick}
          minimumDatePickerDate={minDate}
          maximumDatePickerDate={maxDate}
          datePickerValue={datePickerValue}
          isLoading={isLoadingLastAvailableDay}
          isLoadingAggregation={isLoadingMeasurements}
          aggregationType={customAggregationType}
          handleModalOpen={() => setModalOpen(true)}
          customAggregation
          aggregationData={aggregationData}
          date={date}
          setDate={setDate}
          type={type}
          hasChfValues={hasChfValues}
          graphType={graphType}
        />
      ),
    },
  ]

  return (
    <>
      <Container
        sx={{
          pt: 5,
        }}
      >
        <Typography variant={'heading2'}>{t('power_consumption_page.energy_title_energy_consumption')}</Typography>
        {isLoadingContracts ? (
          <CircularProgress />
        ) : (
          <>
            <DtpDropdown
              activeLocations={activeLocations}
              expiredLocations={expiredLocations}
              value={selectedItem.value}
              onLocationsSelect={setSelectedItem}
              noDataLabel={t('power_consumption_page.energy_no_contracts')}
              placeholder={t('power_consumption_page.energy_select_contract')}
              showExpiredContracts={showExpiredContracts}
              handleChangeExpireContracts={handleChangeExpireContracts}
              isLoading={isLoadingContracts}
            />

            <Show when={allLocations.length === 0}>
              <Typography variant="heading4" pt={3}>
                {t('power_consumption_page.error_message_no_contracts_description')}
              </Typography>
            </Show>
          </>
        )}

        <Show when={!!selectedItem?.value}>
          <DtpTabs
            activeTab={activeTab}
            onChange={handleTabChange}
            onClick={handleCustomRangeClick}
            tabs={tabs}
            controlsSlot={
              <Box>
                <Show when={showConsumptionSwitch}>
                  <DtpConsumptionSwitch
                    switchOptions={switchOptions}
                    selectedOption={graphType}
                    onChange={setGraphType}
                    hasReactivePower={hasReactivePower}
                    hasMaxPower={isMaxPowerAvailable}
                  />
                </Show>
                <Show when={isLoadingMeasurements || isLoadingLastAvailableDay}>
                  <ConsumptionSwitchSkeleton />
                </Show>
              </Box>
            }
          />
        </Show>

        <Box display={isComparisonCardVisible && allLocations.length > 0 ? 'block' : 'none'}>
          <Box display={{ xs: 'none', md: 'block' }}>
            <Grid container mt={4}>
              <Grid size={{ xs: 6 }} pr={2}>
                <DtpCompareCardV2
                  aggregationType="month"
                  datePickerValue={lastAvailableDay ?? dayjs().format('YYYY-MM-DD')}
                  title={t('power_consumption_page.monthly_comparison')}
                  isLoading={isLoadingLastAvailableDay}
                  onBarClick={handleComparisonBarClick}
                />
              </Grid>
              <Grid size={{ xs: 6 }} pl={2}>
                <DtpCompareCardV2
                  aggregationType="year"
                  datePickerValue={lastAvailableDay ?? dayjs().format('YYYY-MM-DD')}
                  title={t('power_consumption_page.yearly_comparison')}
                  isLoading={isLoadingLastAvailableDay}
                  onBarClick={handleComparisonBarClick}
                />
              </Grid>
            </Grid>
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
            <DtpCarousel>
              <DtpCompareCardV2
                aggregationType="month"
                datePickerValue={lastAvailableDay ?? ''}
                title={t('power_consumption_page.monthly_comparison')}
                isLoading={isLoadingLastAvailableDay}
                onBarClick={handleComparisonBarClick}
              />
              <DtpCompareCardV2
                aggregationType="year"
                datePickerValue={lastAvailableDay ?? dayjs().format('YYYY-MM-DD')}
                title={t('power_consumption_page.yearly_comparison')}
                isLoading={isLoadingLastAvailableDay}
                onBarClick={handleComparisonBarClick}
              />
            </DtpCarousel>
          </Box>
        </Box>
      </Container>
      <DateRangeModal
        supplyStart={supplyStartDate}
        supplyEnd={supplyEndDate}
        lastAvailableDay={lastAvailableDay}
        startDate={startDate}
        endDate={endDate}
        modalOpen={modalOpen}
        onConfirmModal={handleConfirmModal}
        onCloseModal={() => setModalOpen(false)}
      />
    </>
  )
}

export default EnergyConsumption

import theme from '@assets/themes/theme'
import { Box, Typography, useMediaQuery } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import Show from '@src/ui/wrappers/Show/Show'
import { useState, useRef, useEffect, useMemo } from 'react'

const PADDING_SIZE = 4
const GAP_SIZE = 4
const ITEM_HEIGHT = 34

type SwitchOption = { label?: string; value: string; icon?: React.ReactNode }

export type ToggleButtonProps = {
  switchOptions: SwitchOption[]
  onChange: (value: string | null) => void
  selectedOption: string | null
  hasReactivePower: boolean
  hasMaxPower: boolean
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '&.MuiToggleButtonGroup-root': {
    display: 'flex',
    alignItems: 'center',
    padding: PADDING_SIZE,
    backgroundColor: theme.palette.gray['50'],
    border: `1px solid ${theme.palette.gray['100']}`,
    borderRadius: 50,
    height: 42,
    gap: GAP_SIZE,
    '& .MuiToggleButtonGroup-grouped': {
      border: 0,
      borderRadius: 50,
      '&.Mui-disabled': {
        border: 0,
      },
    },
  },
}))

const StyledToggleButton = styled(ToggleButton)(() => ({
  '&.MuiToggleButton-root': {
    zIndex: 1,
    flex: 1,
    height: ITEM_HEIGHT,
    padding: theme.spacing(0, 2),
    border: 0,
    borderRadius: 50,
    textTransform: 'none',
    '& > svg': {
      color: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-selected': {
      color: theme.palette.background.paper,
      backgroundColor: 'transparent',
      '& *': {
        stroke: theme.palette.green['600'],
      },
    },
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },
}))

const SwitchBackgroundSelector = styled(Box)(() => ({
  position: 'absolute',
  top: 4,
  left: 0,
  bottom: PADDING_SIZE,
  zIndex: 0,
  height: ITEM_HEIGHT,
  [theme.breakpoints.down('sm')]: {
    left: 0,
  },
  '&.MuiBox-root': {
    border: `1px solid ${theme.palette.green['500']}`,
    background: theme.palette.green['50'],
    borderRadius: 50,
    transition: 'transform 150ms cubic-bezier(0.33, 1, 0.68, 1)',
  },
}))

const DtpConsumptionSwitch = ({
  switchOptions,
  selectedOption,
  onChange,
  hasReactivePower,
  hasMaxPower,
}: ToggleButtonProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [itemWidth, setItemWidth] = useState(0)
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([])

  const handleChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: string | null) => {
    onChange(value)
  }

  const filteredSwitchOptions = useMemo(() => {
    if (!hasMaxPower) {
      return switchOptions.filter((option) => option.value !== 'maxPower')
    }
    if (!hasReactivePower) {
      return switchOptions.slice(0, -1)
    }
    return switchOptions
  }, [switchOptions, hasMaxPower, hasReactivePower])

  const selectedIndex = useMemo(() => {
    return filteredSwitchOptions.findIndex(({ value }) => value === selectedOption)
  }, [filteredSwitchOptions, selectedOption])

  useEffect(() => {
    if (buttonsRef.current[selectedIndex]) {
      const buttonWidth = buttonsRef.current[selectedIndex]?.offsetWidth ?? 0
      setItemWidth(buttonWidth)
    }
  }, [selectedIndex, filteredSwitchOptions, isMobile])

  return (
    <Box position="relative" width={{ xs: '100%', sm: 'auto' }}>
      <StyledToggleButtonGroup value={selectedOption} exclusive onChange={handleChange} aria-label="switch">
        {filteredSwitchOptions.map(({ value, icon, label }, index) => (
          <StyledToggleButton
            key={value}
            value={value}
            aria-label={value}
            disabled={selectedOption === value}
            disableRipple
            ref={(el) => {
              buttonsRef.current[index] = el
            }}
          >
            <Show when={isMobile}>{icon}</Show>
            <Show when={selectedOption === value || !isMobile}>
              <Typography
                sx={{
                  color: selectedOption === value ? theme.palette.green['500'] : 'initial',
                  margin: isMobile && label && icon ? '1px 0 0 10px' : '1px 0 0 0',
                  transition: 'transform 150ms cubic-bezier(0.33, 1, 0.68, 1)',
                  whiteSpace: 'nowrap',
                  fontSize: theme.typography.pxToRem(16),
                  lineHeight: theme.typography.pxToRem(25.6),
                  fontWeight: 500,
                }}
                variant="bodyRegularEmphasized"
              >
                {label}
              </Typography>
            </Show>
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
      <SwitchBackgroundSelector
        sx={{
          width: itemWidth,
          transform: `translateX(${buttonsRef.current[selectedIndex]?.offsetLeft ?? 0}px)`,
        }}
      />
    </Box>
  )
}

export default DtpConsumptionSwitch

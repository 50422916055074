import { MaxPowerIcon } from '@assets/icons'
import theme from '@assets/themes/theme'
import DtpInfoTooltip from '@components/DtpInfoTooltip/DtpInfoTooltip'
import { isNil } from '@helpers/functional'
import { Stack, Typography, Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import Show from '@src/ui/wrappers/Show/Show'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface ConsumptionItemProps {
  label: string
  value?: string | number | null
  unit: string
  color?: string
  border?: string
  showSquare?: boolean
  showCircle?: boolean
  maxPower?: boolean
  tooltipContent?: React.ReactNode | string
  showTooltip?: boolean
  componentVariant?: 'ConsumptionSum' | 'ChartTooltip'
  customLabelValue?: string | ReactElement
}

const localNumberFormat = new Intl.NumberFormat('de-CH', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const ConsumptionSumStyle = {
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    textAlign: 'center',
  },
}
const ChartTooltipStyle = {
  marginTop: 0.5,
  marginBottom: 0.5,
  justifyContent: 'space-between',
}

const ChartTooltipLabelStyle = {
  fontSize: 16,
  fontWeight: '200',
  whiteSpace: 'nowrap',
}

const tooltipLabelErrorStyle = {
  fontSize: 16,
  fontWeight: '200',
  whiteSpace: 'wrap',
  ml: 1,
}

const StyledSquare = styled(Box)({
  marginRight: 4,
  width: 9,
  height: 9,
  borderRadius: 2,
})

const StyledCicle = styled(Box)({
  marginRight: 8,
  width: 8,
  height: 8,
  borderRadius: 50,
})

const ConsumptionLabelText = ({ text }: { text: string }) => {
  return (
    <Typography display="inline" component="span" variant="caption">
      {text}
    </Typography>
  )
}

const ConsumptionLabelComponent = ({
  unit,
  numberValue,
  isMobile,
}: {
  unit: string
  numberValue: string | null
  isMobile: boolean
}) => {
  const unitElement = isMobile ? <ConsumptionLabelText text={unit} /> : <>{unit}</>

  return unit === 'CHF' ? (
    <>
      {unitElement} {` ${numberValue}`}
    </>
  ) : (
    <>
      {`${numberValue} `} {unitElement}
    </>
  )
}

const ConsumptionItem = ({
  label,
  value,
  unit,
  color,
  border,
  showSquare,
  showCircle,
  maxPower,
  componentVariant,
  showTooltip,
  tooltipContent,
  customLabelValue,
}: ConsumptionItemProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const numberValue = isNil(value) ? null : localNumberFormat.format(Number(value))
  const consumptionSumMobile = isMobile && componentVariant === 'ConsumptionSum'

  const displayValue = isNil(numberValue) ? null : (
    <ConsumptionLabelComponent unit={unit} numberValue={numberValue} isMobile={consumptionSumMobile} />
  )

  return (
    <Stack
      direction="row"
      sx={{ ...(componentVariant === 'ConsumptionSum' ? ConsumptionSumStyle : ChartTooltipStyle) }}
    >
      <Stack direction="row" alignItems={'center'}>
        <Box flexDirection="row" alignContent="center" alignItems="center" height="100%" color="#fff">
          <Show when={(showSquare && showSquare === true && !maxPower) ?? false}>
            <StyledSquare sx={{ backgroundColor: color }} border={border && `1px dashed ${border}`} my="8px" />
          </Show>
          <Show when={!!showCircle}>
            <StyledCicle sx={{ backgroundColor: color }} mb={0.5} />
          </Show>
          <Show when={(!!showSquare && !!maxPower) ?? false}>
            <MaxPowerIcon sx={{ width: 12, height: 12, mr: 1 }} />
          </Show>
          <Show when={!showSquare && componentVariant === 'ConsumptionSum'}>
            <StyledSquare sx={{ width: 0, mx: 0 }} my="8px" />
          </Show>
        </Box>
        <Box
          flexDirection="row"
          alignContent={componentVariant === 'ConsumptionSum' ? 'center' : 'start'}
          height="100%"
        >
          <Typography
            sx={{
              my: 0,
              ml: showSquare && componentVariant === 'ChartTooltip' ? 0.5 : 0,
              mr: componentVariant === 'ChartTooltip' ? 1 : 0,
              typography: componentVariant === 'ChartTooltip' ? 'bodyRegular' : 'body2',
              ...(componentVariant === 'ChartTooltip' ? ChartTooltipLabelStyle : ''),
              whiteSpace: 'wrap',
            }}
          >
            {label}
          </Typography>
        </Box>
        <Show when={componentVariant === 'ConsumptionSum' && !!showTooltip}>
          <DtpInfoTooltip
            tooltipWidth={300}
            id={label}
            title={
              <Stack direction="row" alignItems="center" width="max-content">
                <Show when={(showSquare && showSquare === true && !maxPower) ?? false}>
                  <StyledSquare sx={{ backgroundColor: color }} border={border && `1px dashed ${border}`} my="10px" />
                </Show>
                <Show when={(!!showSquare && !!maxPower) ?? false}>
                  <MaxPowerIcon sx={{ width: 12, height: 12, mr: 1 }} />
                </Show>
                <Typography sx={{ my: 0 }}>{label}</Typography>
              </Stack>
            }
            content={tooltipContent}
          />
        </Show>
      </Stack>
      <Stack direction="row" alignItems={'center'}>
        <Box sx={componentVariant === 'ChartTooltip' ? { marginLeft: 'auto', my: 'auto' } : { marginLeft: 0, my: 0 }}>
          <Typography
            sx={{
              my: 0,
              typography:
                componentVariant === 'ChartTooltip'
                  ? 'bodyTextEmphasized'
                  : customLabelValue
                    ? 'bodyRegular'
                    : 'bodyRegularEmphasized',
              ...(componentVariant === 'ChartTooltip' ? ChartTooltipLabelStyle : ''),
              ...(value === null ? tooltipLabelErrorStyle : ''),
            }}
          >
            {customLabelValue ? value : (displayValue ?? t('power_consumption_page.error_message_no_data_available'))}
          </Typography>
        </Box>
        <Show when={!!customLabelValue}>
          <DtpInfoTooltip
            tooltipWidth={300}
            id={label}
            title={
              <Stack direction="row" alignItems="center" width="max-content">
                <MaxPowerIcon sx={{ width: 12, height: 12, mr: 1 }} />
                <Typography sx={{ my: 0 }}>{label}</Typography>
              </Stack>
            }
            content={customLabelValue}
          />
        </Show>
      </Stack>
    </Stack>
  )
}

export default ConsumptionItem

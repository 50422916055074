import ConsumptionItem from '@components/ConsumptionItem/ConsumptionItem'
import { formatTooltipTimeInterval } from '@components/charts/DtpMaxPowerChart/utils'
import TooltipButton from '@components/charts/tooltips/components/TooltipButton'
import TooltipWrapper from '@components/charts/tooltips/components/TooltipWrapper'
import { chartAxisEnum } from '@enums/chartAxisFormats'
import { isNil } from '@helpers/functional'
import { Typography, Box } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import { useTranslation, Trans } from 'react-i18next'

type PayloadItem = {
  payload: {
    timestampStartOfPeriod: string
    maxPowerInMonth: string
    feedInMeasured?: string | number
    energy?: string
    energyChf?: string
    maxPower?: string
    reactivePower?: string
    description?: string
    noReactiveDataValue?: string
  }
}

type ReactivePowerTooltip = {
  active?: boolean
  xAxisDate: chartAxisEnum
  payload?: PayloadItem[]
  unit: string
  setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOnDateChange?: (date: string) => void
}

export const ReactivePowerTooltip = ({
  active,
  payload,
  unit,
  setTooltipOpen,
  xAxisDate,
  handleOnDateChange,
}: ReactivePowerTooltip) => {
  const { t } = useTranslation()
  if (!active || !payload) return null

  const tooltipPayload = payload?.[0]?.payload

  const timeInterval = formatTooltipTimeInterval(tooltipPayload.timestampStartOfPeriod, xAxisDate)

  const showRedirectButton = xAxisDate !== chartAxisEnum.HOUR

  const hasDescription = !!tooltipPayload.description

  const hasValue = !isNil(tooltipPayload.reactivePower) && isNil(tooltipPayload.noReactiveDataValue)

  const noDataAvailable = isNil(tooltipPayload.reactivePower) || !isNil(tooltipPayload.noReactiveDataValue)

  const handleRedirect = () => {
    handleOnDateChange?.(tooltipPayload.timestampStartOfPeriod)
    setTooltipOpen(false)
  }

  return (
    <TooltipWrapper setTooltipOpen={setTooltipOpen}>
      <Box mb={2}>
        <Typography variant="body1" sx={{ m: 0 }}>
          {timeInterval}
        </Typography>
      </Box>

      <Show when={hasValue}>
        <ConsumptionItem
          label={t('power_consumption_page.reactive_power')}
          value={tooltipPayload.reactivePower}
          unit={unit}
          color={'#6A716A'}
          componentVariant="ChartTooltip"
          showSquare
        />
      </Show>
      <Show when={noDataAvailable}>
        <Typography variant="bodyRegular" maxWidth={'280px'} fontSize={16}>
          <Trans i18nKey="power_consumption_page.error_message_no_data_available_for_time_period_description_new" />
        </Typography>
      </Show>

      <Show when={hasDescription}>
        <Typography sx={{ fontSize: '16px' }} variant="bodyRegular">
          *{t(`power_consumption_page.${tooltipPayload.description}`)}
        </Typography>
      </Show>

      <Show when={showRedirectButton && hasValue}>
        <Box>
          <TooltipButton onClick={handleRedirect} xAxisDate={xAxisDate} />
        </Box>
      </Show>
    </TooltipWrapper>
  )
}

export default ReactivePowerTooltip
